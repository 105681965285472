import React, { Component } from "react";
import { Row, Button, Col } from "reactstrap";
import { Link } from "react-router-dom";

// images
import logo from "../assets/images/logo-white.png";

// link
import { FiX } from "react-icons/fi";

export default class MobileMenu extends Component {
  render() {
    return (
      <div
        className="mobile-menu"
        style={{
          transition: "0.3s",
          display: this.props.openMobileMenu ? "block" : "none"
        }}
      >
        <Row className="my-4">
          <Col xs={6}>
            <img
              src={logo}
              alt=""
              className="logo wow fadeIn"
              data-wow-duration="1s"
            />
          </Col>
          <Col xs={6} className="text-right">
            <Button
              color="link"
              onClick={() => this.props.handleSidebar()}
              aria-label="Close"
            >
              <FiX aria-hidden="true" size={30} className="text-white" />
            </Button>
          </Col>
        </Row>
        <nav>
          <ul className="mobile-menu-navigation">
            <li className="wow fadeIn" data-wow-duration="1.5s">
              <Link to="/">Homepage</Link>
            </li>
            <li className="wow fadeIn" data-wow-duration="1.5s">
              <Link to="/portfolio">Portfolio</Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
