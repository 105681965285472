import React, { Component } from "react";
import MainNavbar from "../components/MainNavbar";
import MainJumbotron from "../components/MainJumbotron";
import Capabilities from "../components/Capabilities";
import FeaturedPortfolio from "../components/FeaturedPortfolio";
import MainFooter from "../components/MainFooter";
import { Helmet } from "react-helmet";
import Headroom from "react-headroom";
import MobileMenu from "../components/MobileMenu";

export default class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMobileMenu: false,
    };
  }

  handleSidebar() {
    this.setState({
      openMobileMenu: !this.state.openMobileMenu,
    });
  }
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Adjie Indrawan | Frontend Developer & UI/UX Designer for mobile & web</title>
          <link rel="canonical" href="https://adjieindrawan.com" />
        </Helmet>
        <Headroom
          style={{
            WebkitTransition: "all .5s ease-in-out",
            MozTransition: "all .5s ease-in-out",
            OTransition: "all .5s ease-in-out",
            Transition: "all .5s ease-in-out",
          }}
        >
          <MainNavbar
            openMobileMenu={this.state.openMobileMenu}
            handleSidebar={() => this.handleSidebar()}
          />
        </Headroom>
        <MobileMenu
          openMobileMenu={this.state.openMobileMenu}
          handleSidebar={() => this.handleSidebar()}
        />
        <MainJumbotron />
        <Capabilities />
        <FeaturedPortfolio />
        <MainFooter />
      </div>
    );
  }
}
