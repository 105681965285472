import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WOW from "wowjs";

import Homepage from "./containers/Homepage";
import Portfolio from "./containers/Portfolio";
import ScrollToTop from "./ScrollToTop";

export default class App extends Component {
  componentDidMount() {
    new WOW.WOW({
      live: false
    }).init();
  }
  render() {
    return (
      <div>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>
            <Route path="/portfolio">
              <Portfolio />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}
