import img_0 from "./images/img_0.png";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
import img_3 from "./images/img_3.png";

export default {
  v: "5.6.1",
  fr: 50,
  ip: 0,
  op: 150,
  w: 620,
  h: 435,
  nm: "Comp 1",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 382,
      h: 393,
      p: img_0,
      e: 0
    },
    {
      id: "image_1",
      w: 177,
      h: 117,
      p: img_1,
      e: 0
    },
    {
      id: "image_2",
      w: 110,
      h: 73,
      p: img_2,
      e: 0
    },
    {
      id: "image_3",
      w: 544,
      h: 199,
      p: img_3,
      e: 0
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "body",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [320, 238.5, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [191, 196.5, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "left paper",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: 0,
              s: [103.5, 130, 0],
              to: [5.167, 2.667, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: 80,
              s: [134.5, 146, 0],
              to: [0, 0, 0],
              ti: [5.167, 2.667, 0]
            },
            {
              t: 149,
              s: [103.5, 130, 0]
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [88.5, 58.5, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "top paper",
      refId: "image_2",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: 0,
              s: [407, 70.028, 0],
              to: [0, -4.5, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: 80,
              s: [407, 43.028, 0],
              to: [0, 0, 0],
              ti: [0, -4.5, 0]
            },
            {
              t: 149,
              s: [407, 70.028, 0]
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [55, 36.5, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "Gradient Wipe",
          np: 7,
          mn: "ADBE Gradient Wipe",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Transition Completion",
              mn: "ADBE Gradient Wipe-0001",
              ix: 1,
              v: {
                a: 0,
                k: 0,
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Transition Softness",
              mn: "ADBE Gradient Wipe-0002",
              ix: 2,
              v: {
                a: 0,
                k: 0,
                ix: 2
              }
            },
            {
              ty: 10,
              nm: "Gradient Layer",
              mn: "ADBE Gradient Wipe-0003",
              ix: 3,
              v: {
                a: 0,
                k: 3,
                ix: 3
              }
            },
            {
              ty: 7,
              nm: "Gradient Placement",
              mn: "ADBE Gradient Wipe-0004",
              ix: 4,
              v: {
                a: 0,
                k: 3,
                ix: 4
              }
            },
            {
              ty: 7,
              nm: "Invert Gradient",
              mn: "ADBE Gradient Wipe-0005",
              ix: 5,
              v: {
                a: 0,
                k: 0,
                ix: 5
              }
            }
          ]
        }
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "right paper",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: 0,
              s: [517.5, 207.5, 0],
              to: [0, 4.833, 0],
              ti: [0, 0, 0]
            },
            {
              i: {
                x: 0.833,
                y: 0.833
              },
              o: {
                x: 0.167,
                y: 0.167
              },
              t: 80,
              s: [517.5, 236.5, 0],
              to: [0, 0, 0],
              ti: [0, 2.09, 0]
            },
            {
              t: 149,
              s: [517.5, 207.5, 0]
            }
          ],
          ix: 2
        },
        a: {
          a: 0,
          k: [88.5, 58.5, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "trashcan",
      refId: "image_3",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11
        },
        r: {
          a: 0,
          k: 0,
          ix: 10
        },
        p: {
          a: 0,
          k: [302, 335.5, 0],
          ix: 2
        },
        a: {
          a: 0,
          k: [272, 99.5, 0],
          ix: 1
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 150,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
