import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Container,
  Button
} from "reactstrap";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

// images
import logo from "../assets/images/logo.png";

// link
import { FiMenu } from "react-icons/fi";

export default class PortfolioNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openNav: false
    };
    this.openNav = this.openNav.bind(this);
  }

  openNav() {
    this.props.handleSidebar();
  }

  render() {
    return (
      <div>
        <Navbar expand="md" className="bg-white py-lg-4 py-3 nav-border">
          <Container>
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="" className="logo" />
            </Link>
            <Button
              color="link"
              className="navbar-toggler"
              aria-label="toggler"
              onClick={this.openNav}
            >
              <FiMenu size={30} />
            </Button>
            <Collapse navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <AnchorLink href="#featured" className="nav-link">
                    Work
                  </AnchorLink>
                </NavItem>
                <NavItem>
                  <AnchorLink href="#hire" className="nav-link">
                    Clients
                  </AnchorLink>
                </NavItem>
                <NavItem>
                  <AnchorLink href="#hire" className="nav-link">
                    Contact
                  </AnchorLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="tblue"
                    href=" https://api.whatsapp.com/send?phone=6281992000999"
                    target="_blank"
                  >
                    +62 8199 2000 999
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}
