import React, { Component } from "react";
import { Container, Col, Row, Button } from "reactstrap";

// data
import { _allPortfolio } from "../static/projects";

export default class AllPortfolio extends Component {
  render() {
    return (
      <div className="py-5 bg-portfolio" id="work">
        <Container>
          <Row>
            <Col md={8} className="align-self-center">
              <h1>Portfolio</h1>
              <h5 className="tbali">
                I stand behind promise to deliver timely and quality service.
              </h5>
            </Col>
            <Col md={4} className="text-right align-self-center">
              <a
                href="https://adjieindrawan.typeform.com/to/aFmy1O"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="mt-lg-4 btn-act btn-blue">
                  Start a Project
                </Button>
              </a>
            </Col>
          </Row>
          <Row className="mt-5">
            {_allPortfolio.map((data, i) => (
              <Col
                md={6}
                className="my-3 wow fadeIn"
                data-wow-delay={`${(i + 1) * 100}ms`}
                key={i}
              >
                <Row className="no-gutters">
                  <Col xs={12} lg={8}>
                    <img src={data.img} alt="" className="img-fluid" />
                  </Col>
                  <Col
                    xs={12}
                    lg={4}
                    className="pt-3 bg-black pl-3 detail-project"
                  >
                    <span className="tiny-text">CLIENT</span>
                    <p className="text-white">{data.client}</p>
                    <span className="tiny-text">PROJECT</span>
                    <p className="text-white">{data.project}</p>
                    <ul className="list-unstyled list-service ">
                      {data.service.map((row, i) => (
                        <li key={i}>{row}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <Row className="work-stats mt-5">
            <Col
              md={{ size: 3, offset: 1 }}
              className="wow fadeIn"
              data-wow-delay="0.6s"
            >
              <span className="number">9</span>
              <span className="desc">
                Years <br /> in Business
              </span>
            </Col>
            <Col
              md={{ size: 3, offset: 1 }}
              className="wow fadeIn"
              data-wow-delay="0.8s"
            >
              <span className="number">62</span>
              <span className="desc">
                Projects <br /> launched
              </span>
            </Col>
            <Col
              md={{ size: 3, offset: 1 }}
              className="wow fadeIn"
              data-wow-delay="1s"
            >
              <span className="number">
                5k<span className="top">+</span>
              </span>
              <span className="desc">
                Git
                <br /> Contributions
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
