import clientWifi from "../assets/images/client-wifi.png";
import clientHero from "../assets/images/client-hero.png";
import clientPM from "../assets/images/client-pm.png";
import clientFriso from "../assets/images/client-friso.png";
import clientRitelaku from "../assets/images/client-ritelaku.png";
import clientMobipaid from "../assets/images/client-mobipaid.png";
import clientGox from "../assets/images/client-gox.png";
import clientIcw from "../assets/images/client-icw.png";
import clientIx from "../assets/images/client-indonesiax.png";
import clientIndihomeStudy from "../assets/images/client-indihome-study.png";
import clientBale from "../assets/images/client-bale.png";
import clientSei from "../assets/images/client-sei.png";
import clientElog from "../assets/images/client-elog.png";
import clientRMS from "../assets/images/client-rms.png";
import clientBoon4 from "../assets/images/client-boon4.png";

export const _clients = {
  clients: [
    clientWifi,
    clientHero,
    clientPM,
    clientFriso,
    clientRitelaku,
    clientMobipaid,
    clientGox,
    clientIcw,
    clientIx,
    clientIndihomeStudy,
    clientBale,
    clientSei,
    clientElog,
    clientRMS,
    clientBoon4,
  ],
};
