import projectHero from "../assets/images/project-hero.jpg";
import projectWifiid from "../assets/images/project-wifiid.jpg";
import projectFriso from "../assets/images/project-friso.jpg";
import projectPm from "../assets/images/project-pm.jpg";
import projectRitelaku from "../assets/images/project-ritelaku.jpg";
import projectSei from "../assets/images/project-sei.jpg";
import projectFM from "../assets/images/project-foundermate.jpg";
import projectMP from "../assets/images/project-mobipaid.jpg";
import projectCapital from "../assets/images/project-capital.jpg";
import projectKaguya from "../assets/images/project-kaguya.jpg";
import projectWorkolio from "../assets/images/project-worklio.jpg";
import projectTamara from "../assets/images/project-tamara.jpg";

export const _featured = [
  {
    img: projectHero,
    client: "Hero Supermarket",
    project: "Company Profile",
    service: ["UI/UX Design", "Front-end Development", "Back-end Development"]
  },
  {
    img: projectWifiid,
    client: "Telkom Indonesia",
    project: "Company Profile",
    service: ["UI/UX Design", "Front-end Development", "Back-end Development"]
  },
  {
    img: projectFriso,
    client: "Friso",
    project: "Gift Reedem",
    service: ["UI/UX Design", "Front-end Development"]
  },
  {
    img: projectPm,
    client: "Pinjam Modal",
    project: "Mobile App & Web App",
    service: [
      "UI/UX Design",
      "Front-end Development",
      "Application Development"
    ]
  },
  {
    img: projectRitelaku,
    client: "Karebosi Ritelaku",
    project: "Mobile App",
    service: ["UI/UX Design"]
  },
  {
    img: projectSei,
    client: "Stockholm Environment",
    project: "Online Courses",
    service: ["UI/UX Design", "Front-end Development"]
  }
];

export const _allPortfolio = [
  {
    img: projectHero,
    client: "Hero Supermarket",
    project: "Company Profile",
    service: ["UI/UX Design", "Front-end Development", "Back-end Development"]
  },
  {
    img: projectWifiid,
    client: "Telkom Indonesia",
    project: "Company Profile",
    service: ["UI/UX Design", "Front-end Development", "Back-end Development"]
  },
  {
    img: projectFriso,
    client: "Friso",
    project: "Gift Reedem",
    service: ["UI/UX Design", "Front-end Development"]
  },
  {
    img: projectPm,
    client: "Pinjam Modal",
    project: "Mobile App & Web App",
    service: [
      "UI/UX Design",
      "Front-end Development",
      "Application Development"
    ]
  },
  {
    img: projectRitelaku,
    client: "Karebosi Ritelaku",
    project: "Mobile App",
    service: ["UI/UX Design"]
  },
  {
    img: projectFM,
    client: "Foundermate",
    project: "Social Media Platform",
    service: ["UI/UX Design", "Front-end Development"]
  },
  {
    img: projectMP,
    client: "Mobipaid",
    project: "Dashboard Admin",
    service: ["UI/UX Design", "Front-end Development"]
  },
  {
    img: projectSei,
    client: "Stockholm Environment",
    project: "Online Courses",
    service: ["UI/UX Design", "Front-end Development"]
  },
  {
    img: projectCapital,
    client: "Capital Gaming",
    project: "Esport Platform",
    service: ["UI/UX Design", "Front-end Development", "Back-end Development"]
  },
  {
    img: projectKaguya,
    client: "Kaguya Tour",
    project: "Company Profile",
    service: ["UI/UX Design", "Front-end Development"]
  },
  {
    img: projectWorkolio,
    client: "Workolio",
    project: "Social Media Platform",
    service: ["Front-end Development"]
  },
  {
    img: projectTamara,
    client: "Tamara Land",
    project: "Company Profile",
    service: ["UI/UX Design", "Front-end Development"]
  }
];
