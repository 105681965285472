import React, { Component } from "react";
import { Jumbotron, Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import * as animationData from "../assets/animate/adjie";

// icons
import { FaLongArrowAltRight } from "react-icons/fa";

export default class MainJumbotron extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <Jumbotron className="bg-white">
          <Container>
            <Row>
              <Col
                lg={5}
                md={6}
                className="align-self-center wow fadeInUp mb-lg-0 mb-5"
                data-wow-duration="1.5s"
              >
                <h2 className="tblue">adjieindrawan.com -</h2>
                <h5 className="tbali">
                  Hello! I am Adjie Indrawan, Frontend Developer &amp; UI/UX
                  Designer for mobile &amp; web.
                </h5>
                {/* <h6 className="tbali">
                  Currently a Frontend &amp; Design Lead at{" "}
                  <a href="https://pinjammodal.id/" alt="">
                    Pinjam Modal
                  </a>
                </h6> */}
                <Link to="/portfolio">
                  <Button className="mt-4 btn-act btn-blue">
                    View Work <FaLongArrowAltRight className="ml-2" />
                  </Button>
                </Link>
              </Col>
              <Col
                lg={6}
                md={6}
                className="m-auto align-self-center wow fadeIn"
                data-wow-duration="1s"
              >
                <Lottie options={defaultOptions} />
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </div>
    );
  }
}
