import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

export default class Capabilities extends Component {
  render() {
    return (
      <div className="py-5 bg-blue text-white" id="capabilites">
        <Container>
          <Row className="text-center">
            <Col lg={{ size: 10, offset: 1 }}>
              <h1 className="font-weight-bold">Capabilities</h1>
              <p>
                As a designer and developer, I understand the perfect user
                interface should look good and work even better. My vision is to
                create a visually stunning, technically advanced as well as
                friendly website and mobile application for my clients.
              </p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={4} lg={{ size: 3, offset: 1 }} className="m-auto">
              <h3>Creative</h3>
              <div className="reactangle" />
              <ul className="list-unstyled skills mt-3">
                <li className="wow fadeInUp" data-wow-delay="0.2s">
                  Web Design
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.4s">
                  Mobile App Design
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.6s">
                  Email Marketing
                </li>
              </ul>
            </Col>
            <Col md={4} lg={{ size: 3, offset: 1 }} className="m-auto">
              <h3>Development</h3>
              <div className="reactangle" />
              <ul className="list-unstyled skills mt-3">
                <li className="wow fadeInUp" data-wow-delay="0.2s">
                  Front-end Development
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.4s">
                  Application Development
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.6s">
                  Web Apps & Corporate Sites
                </li>
              </ul>
            </Col>
            <Col md={4} lg={{ size: 3, offset: 1 }} className="m-auto">
              <h3>Technology</h3>
              <div className="reactangle" />
              <ul className="list-unstyled skills mt-3">
                <li className="wow fadeInUp" data-wow-delay="0.2s">
                  React, Vue, & React Native
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.4s">
                  HTML, CSS, & JavaScript
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.6s">
                  Redux
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
