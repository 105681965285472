import React, { Component, Fragment } from "react";
import Headroom from "react-headroom";
import { Helmet } from "react-helmet";
import PortfolioNavbar from "../components/PortfolioNavbar";
import Clients from "../components/Clients";
import AllPortfolio from "../components/AllPortfolio";
import LikeMyWork from "../components/LikeMyWork";
import MainFooter from "../components/MainFooter";
import MobileMenu from "../components/MobileMenu";

export default class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMobileMenu: false
    };
  }

  handleSidebar() {
    this.setState({
      openMobileMenu: !this.state.openMobileMenu
    });
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Portfolio | Adjie Indrawan</title>
          <link rel="canonical" href="https://adjieindrawan.com/portfolio" />
        </Helmet>
        <Headroom
          style={{
            WebkitTransition: "all .5s ease-in-out",
            MozTransition: "all .5s ease-in-out",
            OTransition: "all .5s ease-in-out",
            Transition: "all .5s ease-in-out"
          }}
        >
          <PortfolioNavbar
            openMobileMenu={this.state.openMobileMenu}
            handleSidebar={() => this.handleSidebar()}
          />
        </Headroom>
        <MobileMenu
          openMobileMenu={this.state.openMobileMenu}
          handleSidebar={() => this.handleSidebar()}
        />
        <AllPortfolio />
        <Clients />
        <LikeMyWork />
        <MainFooter />
      </Fragment>
    );
  }
}
