import React, { Component } from "react";

export default class LikeMyWork extends Component {
  render() {
    return (
      <div className="py-5 text-center bg-blue text-white">
        <h1>Like my work?</h1>
        <h5>Let’s talk about your project and how I can help you.</h5>
        <a href="mailto:adjieindrawan@gmail.com" className="mt-5 tblack">
          <h4 className="mt-5">Shoot me a message</h4>
        </a>
      </div>
    );
  }
}
