import React, { Component } from "react";
import { Container, Col, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";

// icons
import { FaAngleRight } from "react-icons/fa";

// data
import { _featured } from "../static/projects";

export default class FeaturedPortfolio extends Component {
  render() {
    return (
      <div className="py-5 bg-portfolio" id="featured">
        <Container>
          <Row>
            <Col md={6}>
              <h1>Featured Work</h1>
              <h5 className="tbali">
                I stand behind promise to deliver timely and quality service.
              </h5>
            </Col>
            <Col md={6} className="text-lg-right mt-4 mt-md-0">
              <a
                href="https://adjieindrawan.typeform.com/to/aFmy1O"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="mt-lg-4 btn-act btn-blue">
                  Start a Project
                </Button>
              </a>
              <Link to="/portfolio">
                <Button className="mt-2 mt-lg-4 btn-act btn-white ml-lg-3">
                  Learn More
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="mt-5">
            {_featured.map((data, i) => (
              <Col
                md={6}
                className="my-3 wow fadeIn"
                data-wow-delay={`${(i + 1) * 100}ms`}
                key={i}
              >
                <Row className="no-gutters">
                  <Col xs={12} lg={8}>
                    <img src={data.img} alt="" className="img-fluid" />
                  </Col>
                  <Col
                    xs={12}
                    lg={4}
                    className="pt-3 bg-black pl-3 detail-project"
                  >
                    <span className="tiny-text">CLIENT</span>
                    <p className="text-white">{data.client}</p>
                    <span className="tiny-text">PROJECT</span>
                    <p className="text-white">{data.project}</p>
                    <ul className="list-unstyled list-service ">
                      {data.service.map((row, i) => (
                        <li key={i}>{row}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
          <div className="text-right mt-3 mb-5">
            <h5 className="tbold">
              <Link to="/portfolio" className="tblack">
                See All Portfolio <FaAngleRight style={{ marginTop: "-3px" }} />
              </Link>
            </h5>
          </div>
        </Container>
      </div>
    );
  }
}
