import React, { Component } from "react";
import { Col, Container, Row, Button } from "reactstrap";

// icons
import {
  FaInstagram,
  FaBehance,
  FaPinterest,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";

export default class MainFooter extends Component {
  render() {
    return (
      <div>
        <div className="py-5" id="hire">
          <Container>
            <Row>
              <Col
                md={12}
                lg={6}
                className="align-self-center wow fadeInLeft"
                data-wow-delay="0.2s"
              >
                <h1>Let’s work together</h1>
                <h5 className="tbali">
                  If you have a website or mobile app idea in mind or you need
                  some advice about product design, feel free to contact me.
                </h5>
                <h4 className="my-4 tblack">
                  <a className="cta-link" href="mailto:adjieindrawan@gmail.com">
                    adjieindrawan@gmail.com
                  </a>
                </h4>
                <a
                  href="https://adjieindrawan.typeform.com/to/aFmy1O"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button className="mt-lg-4 btn-act btn-blue">
                    Start a Project
                  </Button>
                </a>
              </Col>
              <Col md={12} lg={6} className="align-self-center mt-5 mt-lg-0">
                <h3 className="wow fadeInUp" data-wow-delay="0.2s">
                  Stay up-to-date
                </h3>
                <Row className="my-3 my-md-4 mt-lg-5">
                  <Col
                    xs={6}
                    md={4}
                    className="wow fadeInUp my-2"
                    data-wow-delay="0.2s"
                  >
                    <a
                      href="https://www.instagram.com/adjieindrawan/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram size={32} className="mr-2" />{" "}
                      <span className="tblack">Instagram</span>
                    </a>
                  </Col>
                  <Col
                    xs={6}
                    md={4}
                    className="wow fadeInUp my-2"
                    data-wow-delay="0.4s"
                  >
                    <a
                      href="https://www.behance.net/adjieindrawan"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaBehance size={32} className="mr-2" />{" "}
                      <span className="tblack">Behance</span>
                    </a>
                  </Col>
                  <Col
                    xs={6}
                    md={4}
                    className="wow fadeInUp my-2"
                    data-wow-delay="0.6s"
                  >
                    <a
                      href="https://www.pinterest.com/adjieindrawan/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaPinterest size={32} className="mr-2" />{" "}
                      <span className="tblack">Pinterest</span>
                    </a>
                  </Col>
                  <Col
                    xs={6}
                    md={4}
                    className="wow fadeInUp my-2"
                    data-wow-delay="0.8s"
                  >
                    <a
                      href="https://www.linkedin.com/in/adjieindrawan/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin size={32} className="mr-2" />{" "}
                      <span className="tblack">LinkedIn</span>
                    </a>
                  </Col>
                  <Col
                    xs={6}
                    md={4}
                    className="wow fadeInUp my-2"
                    data-wow-delay="0.8s"
                  >
                    <a
                      href="https://api.whatsapp.com/send?phone=6281992000999"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaWhatsapp size={32} className="mr-2" />{" "}
                      <span className="tblack">Whatsapp</span>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-black text-white-50 text-center py-4">
          adjieindrawan.com @{new Date().getFullYear()}
        </div>
      </div>
    );
  }
}
