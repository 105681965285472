import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

// data clients
import { _clients } from "../static/clients";

export default class Clients extends Component {
  render() {
    return (
      <div className="py-5" id="hire">
        <Container>
          <Row>
            <Col md={{ size: 10, offset: 1 }} className="text-center">
              <h3>Clients</h3>
              <h5 className="tbali mt-3">
                I work with clients of all scopes and sectors to create better
                experiences through design. Whether an adventurous startup or
                established global presence, the same level of detail and
                quality is delivered.
              </h5>
            </Col>
            <Col xs={12} lg={{ size: 10, offset: 1 }} className="mt-4">
              <ul className="client-logo">
                {_clients.clients.map((data, i) => (
                  <li
                    key={i}
                    className="wow fadeIn"
                    data-wow-delay={`${(i + 1) * 100}ms`}
                  >
                    <img src={data} alt="" />
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
